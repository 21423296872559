import React, {Component} from 'react';
import {Alert, Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from 'react-bootstrap';
import {connectModal, hide} from 'redux-modal';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import NumericInput from 'react-numeric-input';
import {bindActionCreators, compose} from 'redux';
import {APPROVED, DECLINED} from './EventStatus';
import {connect} from 'react-redux';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import {createEvent, deleteEvent, updateEvent} from '../../actions/EventActions';
import axios from 'axios';
import {API_BASE_URL} from '../../config';

class EventModal extends Component {

    constructor(props) {
        let activityGroups = [
            {
                "id": 2,
                "name": "Productief"
            },
            {
                "id": 5,
                "name": "Project support"
            },
            {
                "id": 6,
                "name": "Team Habitus & kantoor"
            },
            {
                "id": 7,
                "name": "Klanten & partners"
            },
            {
                "id": 8,
                "name": "Missie & maatschappij"
            },
            {
                "id": 9,
                "name": "Strategie"
            },
            {
                "id": 10,
                "name": "Beleid intern"
            },
            {
                "id": 11,
                "name": "Projectencirkel"
            },
            {
                "id": 12,
                "name": "Strategieteam"
            },
            {
                "id": 13,
                "name": "Overige rollen en taken"
            },
            {
                "id": 3,
                "name": "Verlof"
            },
        ];
        super();
        this.state = {
            decline_message: props.slotInfo.decline_message || '',
            activity_groups: activityGroups,
            projects: [],
            activities: [],
            id: props.slotInfo.id || '',
            activity_group: props.slotInfo.activity_group || activityGroups[0],
            project: props.slotInfo.project,
            activity: props.slotInfo.activity,
            status: props.slotInfo.status,
            start: props.slotInfo.start_date
                ? moment(props.slotInfo.start_date)
                : moment(props.slotInfo.start),
            end: props.slotInfo.end_date
                ? moment(props.slotInfo.end_date)
                : moment(props.slotInfo.end),
            km: props.slotInfo.km || 0,
            notes: props.slotInfo.notes || ''
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.isNewEvent = this.isNewEvent.bind(this);
    }

    componentDidMount() {
        axios({
            method: 'POST',
            url: `${API_BASE_URL}/projects.json`,
            data: {
                token: this.props.loginData.token,
                activity_group_id: this.state.activity_group.id
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                this.setState({projects: res.data});
            })
            .catch(function (error) {
                console.error(error);
            });

        if (this.state.project !== undefined) {
            axios({
                method: 'POST',
                url: `${API_BASE_URL}/activities.json`,
                data: {
                    token: this.props.loginData.token,
                    customer_project_id: this.state.project.id
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
                .then(res => {
                    this.setState({
                        activities: res.data
                    });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        let duration = moment.duration(this.state.end.diff(this.state.start));
        this.setState({
            total_hours: duration.asHours()
        });
    }

    handleStartChange = startTime => {
        this.setState({
            start: startTime,
            total_hours: moment.duration(this.state.end.diff(startTime)).asHours()
        });
    };

    handleEndChange = endTime => {
        this.setState({
            end: endTime,
            total_hours: moment.duration(moment(endTime).diff(this.state.start)).asHours()
        });
    };

    handleActivityGroupChange = selectedOption => {
        this.setState({activity_group: selectedOption, activities: [], activity: null, project: null, projects: []});
        axios({
            method: 'POST',
            url: `${API_BASE_URL}/projects.json`,
            data: {
                token: this.props.loginData.token,
                activity_group_id: selectedOption.id
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                let newProjects = res.data;
                if (newProjects.length > 0) {
                    this.setState({projects: newProjects, project: newProjects[0]});
                    this.handleProjectChange(newProjects[0]);
                } else {
                    this.setState({projects: newProjects});
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    handleProjectChange = selectedOption => {
        this.setState({project: selectedOption});

        if (selectedOption) {
            axios({
                method: 'POST',
                url: `${API_BASE_URL}/activities.json`,
                data: {
                    token: this.props.loginData.token,
                    customer_project_id: selectedOption.id
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
                .then(res => {
                    this.setState({activities: res.data, activity: res.data[0]});
                })
                .catch(function (error) {
                    console.error(error);
                });
        } else {
            this.setState({activities: [], activity: null});
        }
    };

    handleActivityChange = selectedOption => {
        this.setState({activity: selectedOption});
    };

    handleKmChange = newKm => {
        this.setState({km: newKm});
    };

    handleNotesChange = event => {
        this.setState({notes: event.target.value});
    };

    handleSave() {
        const dataToSave = {
            id: this.state.id,
            title: `${this.state.project.name} (${this.state.activity.name})`,
            start_date: moment(this.state.start.toDate()).format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            end_date: moment(this.state.end.toDate()).format('YYYY-MM-DD HH:mm:ss'),
            activity_group_id: this.state.activity_group.id,
            customer_project_id: this.state.project.id,
            activity_id: this.state.activity.id,
            km: this.state.km,
            notes: this.state.notes,
        };

        if (this.isNewEvent()) {
            this.props.createEvent(this.props.loginData.token, dataToSave);
        } else {
            this.props.updateEvent(this.props.loginData.token, dataToSave);
        }

        this.props.hide('event');
    }

    handleDelete() {
        this.props.deleteEvent(this.props.loginData.token, this.props.slotInfo);
        this.props.hide('event');
    }

    isNewEvent() {
        return this.state.id === '';
    }

    isApprovedEvent() {
        return this.state.status === APPROVED;
    }

    newTimeOverlapsWithExistingTimes = function () {
        let isBetween = false;
        let id = this.state.id;
        let start = (typeof this.state.start === 'string') ? moment(this.state.start, "DD-MM-YYYY") : this.state.start;
        let end = (typeof this.state.end === 'string') ? moment(this.state.end, "DD-MM-YYYY") : this.state.end;
        this.props.events.forEach(function (e) {
            if (!isBetween && id !== e.id &&
                (start.isSame(e.start_date)
                    || start.isBetween(e.start_date, e.end_date)
                    || end.isBetween(e.start_date, e.end_date)
                    || moment(e.start_date).isBetween(start, end))) {
                isBetween = true;
            }
        });
        return isBetween;
    };

    render() {
        const {show, handleHide} = this.props;
        const isFormValid =
            this.state.project !== undefined &&
            this.state.activity !== undefined &&
            this.state.km >= 0 &&
            [0, 15, 30, 45].indexOf(moment(this.state.start).minutes()) !== -1 &&
            [0, 15, 30, 45].indexOf(moment(this.state.end).minutes()) !== -1
            && !this.newTimeOverlapsWithExistingTimes() && !this.isApprovedEvent();

        const addOrEdit = this.isNewEvent() ? 'Toevoegen nieuw' : 'Bewerken';

        let errorMessage;
        if (this.state.decline_message !== '' && this.state.status === DECLINED) {
            errorMessage = (
                <Alert bsStyle="danger">{this.state.decline_message}</Alert>
            );
        }

        if (this.newTimeOverlapsWithExistingTimes()) {
            errorMessage = (
                <Alert bsStyle="danger">Uren mogen niet overlappen met andere reeds ingevulde uren.</Alert>
            );
        }

        if (this.isApprovedEvent()) {
            errorMessage = (
                <Alert bsStyle="danger">Deze uren zijn reeds goedgekeurd en kunnen niet meer worden gewijzigd.</Alert>
            );
        }

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{addOrEdit} tijdslot</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {errorMessage}
                    <Row className="show-grid">
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <ControlLabel>Begin (alleen per kwartier invullen)</ControlLabel>
                                <Datetime
                                    value={this.state.start}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat="HH:mm"
                                    onChange={this.handleStartChange}
                                    timeConstraints={{minutes: {min: 0, max: 59, step: 15}}}
                                    inputProps={{disabled: this.isApprovedEvent()}}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <ControlLabel>Eind (alleen per kwartier invullen)</ControlLabel>
                                <Datetime
                                    value={this.state.end}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat="HH:mm"
                                    onChange={this.handleEndChange}
                                    timeConstraints={{minutes: {min: 0, max: 59, step: 15}}}
                                    inputProps={{disabled: this.isApprovedEvent()}}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <ControlLabel>Totaal aantal uren</ControlLabel>
                        <FormControl
                            value={this.state.total_hours}
                            className="form-control"
                            disabled={true}
                        />
                    </FormGroup>
                    <Row className="show-grid">
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <ControlLabel>Dienst, Cirkel of Verlof</ControlLabel>
                                <Select
                                    name="type"
                                    value={this.state.activity_group}
                                    onChange={this.handleActivityGroupChange}
                                    valueKey="id"
                                    labelKey="name"
                                    options={this.state.activity_groups}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <ControlLabel>Hoofdactiviteit</ControlLabel>
                                <Select
                                    name="project"
                                    value={this.state.project}
                                    onChange={this.handleProjectChange}
                                    valueKey="id"
                                    labelKey="name"
                                    options={this.state.projects}
                                    disabled={this.isApprovedEvent()}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <ControlLabel>Activiteit</ControlLabel>
                                <Select
                                    name="activity"
                                    value={this.state.activity}
                                    onChange={this.handleActivityChange}
                                    valueKey="id"
                                    labelKey="name"
                                    options={this.state.activities}
                                    disabled={this.state.project === undefined || this.isApprovedEvent()}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <ControlLabel>Notities</ControlLabel>
                        <FormControl
                            value={this.state.notes}
                            onChange={this.handleNotesChange}
                            className="form-control"
                            componentClass="textarea"
                            disabled={this.isApprovedEvent()}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Kilometer registratie</ControlLabel>
                        <NumericInput
                            min={0}
                            value={this.state.km}
                            onChange={this.handleKmChange}
                            className="form-control"
                            disabled={this.isApprovedEvent()}
                        />
                    </FormGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleHide}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        onClick={this.handleSave}
                        disabled={!isFormValid}
                    >
                        Save
                    </Button>
                    <span className="pull-left">
            <Button
                bsStyle="danger"
                onClick={this.handleDelete}
                disabled={this.isNewEvent() || this.isApprovedEvent()}
            >
              Verwijderen
            </Button>
          </span>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    events: state.events || [],
    loginData: state.login
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            hide,
            createEvent,
            updateEvent,
            deleteEvent
        },
        dispatch
    );

export default compose(
    connectModal({name: 'event'}),
    connect(mapStateToProps, mapDispatchToProps)
)(EventModal);
