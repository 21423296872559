import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { LOGIN_SET_STATUS } from './actions';

export function doLogin(loginData) {
  return {type: LOGIN_SET_STATUS, payload: loginData};
}

export function login(loginData) {
  let data = JSON.stringify(loginData);

  return dispatch => {
    axios
      .post(`${API_BASE_URL}/authenticate.json`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (res) {
        if (res.data.token !== undefined) {
          localStorage.setItem('token', res.data.token);
        } else {
          localStorage.removeItem('token');
        }
        dispatch(doLogin(res.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}
