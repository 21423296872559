import React from 'react';
import Table from "react-bootstrap/es/Table";
import moment from "moment";

const UserData = (props) => (
    <div>
        <h2>Mijn gegevens</h2>
        <Table striped bordered condensed hover>
            <thead>
            <tr>
                <th>Naam</th>
                <th>Contracturen</th>
                <th>In-dienst datum</th>
                <th>Uit-dienst datum</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{props.employee.user.firstname} {props.employee.user.lastname}</td>
                <td>{props.employee.contract_hours}</td>
                <td>{(props.employee.employed_date) ? moment(props.employee.employed_date).format('DD-MM-YYYY') : '-'}</td>
                <td>{(props.employee.unemployed_date) ? moment(props.employee.unemployed_date).format('DD-MM-YYYY') : '-'}</td>
            </tr>
            </tbody>
        </Table>
    </div>
);

export default UserData;