import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Image, Nav, Navbar, NavItem} from 'react-bootstrap';
import TimeTable from '../timetable/TimeTable';
import Login from '../login/Login';
import './App.css';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Route, Switch, withRouter} from 'react-router';
import {login} from "../login/LoginActions";
import Spinner from "../login/Spinner";
import TimesLeave from "../timesleave/TimesLeave";
import ExportModal from "../timetable/ExportModal";
import {show} from "redux-modal";

class App extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem('token');
        this.state = {
            token: token
        };
    }

    componentDidMount() {
        if (this.state.token !== null) {
            this.props.login({token: this.state.token});
        }
    }

    handleLogout() {
        localStorage.removeItem('token');
        window.location.reload();
    }

    handleTimes() {
        window.location = '/'
    }

    handleTimesLeave() {
        window.location = '/timesleave'
    }

    render() {
        let viewToShow = Login;
        if (this.state.token) {
            viewToShow = Spinner;
        }

        let logoutButton = '';
        let timesButton = '';
        let timesLeaveButton = '';
        let timesExportButton = '';
        if (this.props.loginData.token) {
            viewToShow = TimeTable;
            logoutButton = <Button bsStyle="primary" onClick={this.handleLogout}>Uitloggen</Button>
            timesButton = <Button onClick={this.handleTimes}>Urenregistratie</Button>
            timesLeaveButton = <Button onClick={this.handleTimesLeave}>Verlofoverzicht</Button>
            timesExportButton = <Button onClick={() => this.props.show('export')}>Uren exporteren</Button>
        }

        if(this.props.loginData.token && this.props.location.pathname === '/timesleave') {
            viewToShow = TimesLeave;
        }

        return (
            <div>
                <header>
                    <Navbar collapseOnSelect>
                        <div className="row">
                            <Navbar.Header>
                                <Navbar.Brand>
                                    <Link to="/">
                                        <Image src="/logo.png"/>
                                    </Link>
                                </Navbar.Brand>
                                <Navbar.Toggle/>
                            </Navbar.Header>
                            <Navbar.Collapse>
                                <Nav pullRight>
                                    <NavItem>
                                        {timesExportButton}
                                    </NavItem>
                                    <NavItem>
                                        {timesButton}
                                    </NavItem>
                                    <NavItem>
                                        {timesLeaveButton}
                                    </NavItem>
                                    <NavItem>
                                        {logoutButton}
                                    </NavItem>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </header>

                <main className="container">
                    <div className="row">
                        <div className="col-12">
                            <Switch>
                                <Route exact path="/" component={viewToShow}/>
                                <Route path="/show/:year/:week" component={viewToShow}/>
                                <Route path="/login" component={Login}/>
                                <Route path="/timesleave" component={viewToShow}/>
                            </Switch>
                        </div>
                    </div>
                </main>

                <ExportModal name="export"/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginData: state.login,
    location: state.routing.location
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            show,
            login
        },
        dispatch
    );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
