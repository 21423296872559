import React, {Component} from 'react';
import moment from 'moment';
import EventModal from './EventModal';
import {show} from 'redux-modal';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {getAllEvents, updateEvent} from '../../actions/EventActions';
import {APPROVED, DECLINED} from './EventStatus';
import HTML5Backend from 'react-dnd-html5-backend'
import {DragDropContext} from 'react-dnd'
import BigCalendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../less/index.css'
import 'moment/locale/nl';
import {API_BASE_URL, APP_VERSION} from "../../config";
import {withRouter} from 'react-router';
import axios from "axios";
import WeekStatatistics from "./WeekStatistics";
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

class TimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarView: 'week',
            calendarDate: (this.props.match.params.week !== undefined) ? moment().day("Monday").year(this.props.match.params.year).week(this.props.match.params.week).toDate() : new Date(),
            views: ['week', 'day'],
            employee: {is_project_leader: false}
        };
    }

    componentDidMount() {
        let startDate = moment(this.state.calendarDate).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
        let endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD 23:59:59');
        this.props.getAllEvents(this.props.loginData.token, startDate, endDate);

        axios({
            method: 'POST',
            url: `${API_BASE_URL}/employeedetails.json`,
            data: {
                token: this.props.loginData.token,
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(res => {
                this.setState({
                    employee: res.data
                });
            })
            .catch(function (error) {
                alert('Something went wrong.');
                console.log(error);
            });
    }

    handleOpen = (name, slotInfo) => {
        this.props.show(name, slotInfo);
    };

    parseStartDate = event => {
        return moment(event.start_date).toDate();
    };

    parseEndDate = event => {
        return moment(event.end_date).toDate();
    };

    moveEvent = (event, start, end) => {
        let updatedEvent = event.event;
        if (updatedEvent.status === APPROVED) {
            alert('Goedgekeurde uren kunnen niet meer worden gewijzigd.');
        } else {
            updatedEvent.customer_project_id = updatedEvent.project.id;
            updatedEvent.activity_id = updatedEvent.activity.id;
            updatedEvent.start_date = moment(event.start).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            updatedEvent.end_date = moment(event.end).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            this.props.updateEvent(this.props.loginData.token, updatedEvent);
        }
    };

    resizeEvent = (resizeType, {event, start, end}) => {
        if (event.status === APPROVED) {
            alert('Goedgekeurde uren kunnen niet meer worden gewijzigd.');
        } else {
            let updatedEvent = event;
            updatedEvent.customer_project_id = updatedEvent.project.id;
            updatedEvent.activity_id = updatedEvent.activity.id;
            if (start !== undefined) {
                updatedEvent.start_date = moment(start).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            if (end !== undefined) {
                updatedEvent.end_date = moment(end).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            this.props.updateEvent(this.props.loginData.token, updatedEvent);
        }
    };

    eventStyleGetter = function (event, start, end, isSelected) {
        switch (event.status) {
            case DECLINED:
                return {
                    style: {
                        backgroundColor: '#bf4d4b',
                        animationDelay: event.eventNum * 10 + 'ms'
                    }
                };
            case APPROVED:
                return {
                    style: {
                        backgroundColor: '#7CB342',
                        animationDelay: event.eventNum * 10 + 'ms'
                    }
                };
            default:
                return {
                    style: {
                        backgroundColor: 'rgb(66, 133, 244)',
                        animationDelay: event.eventNum * 10 + 'ms'
                    }
                };
        }
    };

    calculateTotalHours = function () {
        let employee = this.state.employee;
        let total = 0;
        this.props.events.forEach(function (e) {
            let totalDuration = moment.duration(moment(e.end_date).diff(moment(e.start_date))).asHours();
            if (e.activity.id === 18) { // Night work
                // Only calculate 1.5 from week 5-2019
                if (employee && employee.is_project_leader) {
                    if (moment(e.start_date).year() >= 2020 || moment(e.start_date).week() >= 5) {
                        totalDuration = totalDuration * 1.5;
                    } else {
                        totalDuration = totalDuration * 1.25;
                    }
                }
            }
            total += totalDuration

        });
        return Math.round(total * 100) / 100;
    }

    render() {

        this.props.events.forEach(function (e, i) {
            e['eventNum'] = i;
        });

        console.log("App version: " + APP_VERSION);


        moment.locale('nl');
        BigCalendar.momentLocalizer(moment);

        return (
            <div>
                <WeekStatatistics employee={this.state.employee} totalHours={this.calculateTotalHours()}/>
                <DragAndDropCalendar
                    selectable
                    events={this.props.events}
                    defaultView={this.state.calendarView}
                    views={this.state.views}
                    step={15}
                    onView={view => {
                        this.setState({
                            calendarView: view
                        });
                    }}
                    onNavigate={date => {
                        this.props.getAllEvents(this.props.loginData.token, moment(date).startOf('isoWeek').add(2, 'hours'), moment(date).startOf('isoWeek').add(6, 'days').add(26, 'hours').add(59, 'minutes'));
                        this.setState({
                            calendarDate: date
                        });
                        let newDate = moment(date);
                        this.props.history.push(`/show/${newDate.year()}/${newDate.week()}`);
                    }}
                    onEventDrop={this.moveEvent}
                    resizable
                    onEventResize={this.resizeEvent}
                    scrollToTime={this.state.calendarDate}
                    defaultDate={this.state.calendarDate}
                    startAccessor={this.parseStartDate}
                    endAccessor={this.parseEndDate}
                    onSelectSlot={slotInfo => this.handleOpen('event', {slotInfo})}
                    onSelectEvent={slotInfo => this.handleOpen('event', {slotInfo})}
                    eventPropGetter={this.eventStyleGetter}
                    messages={{next: ">", previous: "<", today: "Vandaag", day: "dag"}}
                    formats={{dayFormat: "dd[\n]D"}}
                />

                <EventModal name="event"/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    events: state.events || [],
    loginData: state.login
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            show,
            getAllEvents,
            updateEvent

        },
        dispatch
    );

export default compose(withRouter, DragDropContext(HTML5Backend), connect(mapStateToProps, mapDispatchToProps))(TimeTable);
