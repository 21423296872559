import {
  EVENTS_PRELOAD,
  EVENT_CREATE,
  EVENT_UPDATE,
  EVENT_DELETE
} from '../actions/actions';

function events(state = [], action) {
  switch (action.type) {
    case EVENTS_PRELOAD:
      return action.payload;

    case EVENT_CREATE:
      return [action.payload].concat(state);

    case EVENT_UPDATE:
      return state.map(event => {
        if (event.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });

    case EVENT_DELETE:
      return state.filter(event => {
        return event.id !== action.payload.id;
      });

    default:
      return state;
  }
}

export default events;
