import {LOGIN_SET_STATUS} from './actions';

function login(state = {}, action) {
  switch (action.type) {
    case LOGIN_SET_STATUS:
      return action.payload;

    default:
      return state;
  }
}

export default login;
