import axios from 'axios';
import { API_BASE_URL } from '../config';
import { EVENT_CREATE, EVENT_DELETE, EVENT_UPDATE, EVENTS_PRELOAD } from './actions';

export function preloadEvents(events) {
  return {type: EVENTS_PRELOAD, payload: events};
}

export function doCreateEvent(event) {
  return {type: EVENT_CREATE, payload: event};
}

export function doUpdateEvent(event) {
  return {type: EVENT_UPDATE, payload: event};
}

export function doDeleteEvent(event) {
  return {type: EVENT_DELETE, payload: event};
}

export function getAllEvents(token, startDate, endDate) {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/get-times.json`,
      data: {
        token: token,
        start_date: startDate,
        end_date: endDate
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
      .then(res => {
        dispatch(preloadEvents(res.data));
      })
      .catch(function (error) {
        dispatch(preloadEvents([]));
      });
  };
}

export function createEvent(token, event) {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/times/add.json`,
      data: {
        token: token,
        activity_group_id: event.activity_group_id,
        customer_project_id: event.customer_project_id,
        activity_id: event.activity_id,
        title: event.title,
        start_date: event.start_date,
        end_date: event.end_date,
        km: event.km,
        notes: event.notes
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
      .then(res => {
        if(res.data.error !== undefined) {
          alert('Er is iets mis gegaan met het schrijven dan de uren. Probeer het nogmaals.');
        } else {
          dispatch(doCreateEvent(res.data.item));
        }
      })
      .catch(function (error) {
        alert('Er is iets mis gegaan met het schrijven dan de uren. Mogelijk is de internetverbinding weggevallen. Probeer het nogmaals.');
        console.error(error);
      });
  };
}

export function updateEvent(token, event) {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/times/edit/${event.id}.json`,
      data: {
        token: token,
        customer_project_id: event.customer_project_id,
        activity_id: event.activity_id,
        title: event.title,
        start_date: event.start_date,
        end_date: event.end_date,
        km: event.km,
        notes: event.notes
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        dispatch(doUpdateEvent(res.data.item));
      })
      .catch(function (error) {
        alert(
          'Something went wrong. Please try again or contact Innovato (info@innovato.nl) when the problem persists.'
        );
        console.error(error);
      });
  };
}

export function deleteEvent(token, event) {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/times/delete/${event.id}.json`,
      data: {
        id: event.id,
        token: token
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        dispatch(doDeleteEvent(event));
      })
      .catch(function (error) {
        alert(
          'Something went wrong. Please try again or contact Innovato (info@innovato.nl) when the problem persists.'
        );
        console.error(error);
      });
  };
}
