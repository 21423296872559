import React, {Component} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../../config";
import './TimesLeave.css';
import {bindActionCreators, compose} from "redux";
import {login} from "../login/LoginActions";
import {connect} from 'react-redux';
import TimesLeaveTable from "./TimesLeaveTable";
import UserData from "./UserData";
import Spinner from "../login/Spinner";
import moment from "moment";

class TimesLeave extends Component {
    constructor() {
        super();
        this.state = {
            token: '',
            times: [],
            employee: null,
        };
    }

    componentDidMount() {
        axios({
            method: 'POST',
            url: `${API_BASE_URL}/timesleave.json`,
            data: {
                token: this.props.loginData.token,
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(res => {
                this.setState({
                    times: res.data
                });
            })
            .catch(function (error) {
                alert('Something went wrong.');
                console.log(error);
            });

        axios({
            method: 'POST',
            url: `${API_BASE_URL}/employeedetails.json`,
            data: {
                token: this.props.loginData.token,
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(res => {
                this.setState({
                    employee: res.data
                });
            })
            .catch(function (error) {
                alert('Something went wrong.');
                console.log(error);
            });
    }

    render() {
        let rows = [];
        let total = 0;

        this.state.times.forEach(function (timesLeave, i) {
            rows.push(
                <tr key={i}>
                    <td>{timesLeave.year}</td>
                    <td>{timesLeave.week}</td>
                    <td>{(timesLeave.hours > 0) ? '+' : ''}{timesLeave.hours}</td>
                    <td>{timesLeave.description}</td>
                    <td>{(timesLeave.times_leave_type_id === 1) ? 'Wettelijke verlofdagen' : 'Tijd voor tijd'}</td>
                    <td>{(timesLeave.validity) ? moment(timesLeave.validity).format('DD-MM-YYYY') : '-'}</td>
                </tr>
            );

            total += timesLeave.hours;
        });

        if (this.state.employee === null) {
            return <Spinner/>;
        }

        return (
            <div className="wrapper">
                <h1>Verlofregistratie</h1>
                <UserData employee={this.state.employee}/>
                <h2>Verlofoverzicht</h2>
                <TimesLeaveTable year={2018} rows={rows} total={total}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginData: state.login
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            login
        },
        dispatch
    );

export default compose(connect(mapStateToProps, mapDispatchToProps))(TimesLeave);
