import React, { Component } from 'react';
import { Alert, Button, FormControl } from 'react-bootstrap';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { login } from "./LoginActions";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  handleEmailChange = event => {
    this.setState({email: event.target.value});
  };

  handlePasswordChange = event => {
    this.setState({password: event.target.value});
  };

  handleLogin = () => {
    this.props.login({
        email: this.state.email,
        password: this.state.password
    });
  };

  render() {
    let errorMessage;
    if (this.props.loginState.error !== undefined) {
      errorMessage = (
        <Alert bsStyle="danger">{ this.props.loginState.error }</Alert>
      );
    }

    return (
      <div className="wrapper">
        <form className="form-signin">
          <h2 className="form-signin-heading">Log in met je Habitus account</h2>
          { errorMessage }
          <FormControl
            value={ this.state.email }
            type="text"
            onChange={ this.handleEmailChange }
            className="form-control"
            placeholder="Jouw e-mailadres"
          />
          <FormControl
            value={ this.state.password }
            type="password"
            onChange={ this.handlePasswordChange }
            className="form-control"
            placeholder="Jouw wachtwoord"
            onKeyPress={ event => {
              if (event.key === "Enter") {
                this.handleLogin();
              }
            } }
          />
          <Button bsStyle="primary" className="btn-block btn-lg" onClick={ this.handleLogin }>
            Inloggen
          </Button>
          <div className="text-center mt-15">
            <a href="https://habitus.nl/mijn-habitus/wachtwoord-vergeten">Wachtwoord vergeten?</a>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginState: state.login
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
