import React from "react";
import Table from "react-bootstrap/es/Table";

const TimesLeaveTable = props => (
  <div>
    <Table striped bordered condensed hover>
      <thead>
        <tr>
          <th>Jaar</th>
          <th>Week</th>
          <th>Bij- of afschrijving</th>
          <th>Beschrijving</th>
          <th>Soort</th>
          <th>Geldigheid</th>
        </tr>
      </thead>
      <tbody>
        {props.rows.length === 0 ? (
          <tr>
            <td colSpan="6">Geen gegevens gevonden</td>
          </tr>
        ) : (
          props.rows
        )}
      </tbody>
    </Table>
    <strong>Resterend: {Math.round(props.total * 100) / 100} uur</strong>
  </div>
);

export default TimesLeaveTable;
