import React from 'react';

const WeekStatatistics = (props) => (
    <div>
        <div className="total-hours">
            <p>Contracturen: {(props.employee) ? props.employee.contract_hours : ''}</p>
            <p>Geboekt: {props.totalHours.toString().replace('.', ',')} uur</p>
            <p>Nog te schrijven: {(props.employee && props.employee.contract_hours > props.totalHours) ? (props.employee.contract_hours - props.totalHours).toString().replace('.', ',') : 0}</p>
        </div>
    </div>
);

export default WeekStatatistics;