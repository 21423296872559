import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormGroup, Modal, Row} from 'react-bootstrap';
import {connectModal, hide} from 'redux-modal';
import Datetime from 'react-datetime';
import moment from 'moment';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import axios from "axios";
import {API_BASE_URL} from "../../config";

class ExportModal extends Component {

    constructor(props) {
        super();
        this.state = {
            start: moment().startOf('month'),
            end: moment().endOf('month')
        };
        this.handleExport = this.handleExport.bind(this);
    }

    handleStartChange = startTime => {
        this.setState({
            start: startTime,
            total_hours: moment.duration(this.state.end.diff(startTime)).asHours()
        });
    };

    handleEndChange = endTime => {
        this.setState({
            end: endTime,
            total_hours: moment.duration(moment(endTime).diff(this.state.start)).asHours()
        });
    };


    handleExport() {
        const dataToExport = {
            token: this.props.loginData.token,
            start_date: moment(this.state.start.toDate()).format(
                'YYYY-MM-DD'
            ),
            end_date: moment(this.state.end.toDate()).format('YYYY-MM-DD'),
        };

        axios({
            method: 'POST',
            url: `${API_BASE_URL}/export-times`,
            data: dataToExport,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => {
                if(response.data.error !== undefined) {
                    alert('Er is iets mis gegaan met het exporteren van de uren. Probeer het nogmaals.');
                } else {
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `urenexport-${moment(dataToExport.start_date).format("DD-MM-YYYY")} - ${moment(dataToExport.end_date).format("DD-MM-YYYY")}.csv`);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
            })
            .catch(function (error) {
                alert('Er is iets mis gegaan met het exporteren van de uren. Mogelijk is de internetverbinding weggevallen. Probeer het nogmaals.');
                console.error(error);
            });

        this.props.hide('event');
    }

    render() {
        const {show, handleHide} = this.props;

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Uren exporteren</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="show-grid">
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <ControlLabel>Begindatum</ControlLabel>
                                <Datetime
                                    value={this.state.start}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    onChange={this.handleStartChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <ControlLabel>Einddatum</ControlLabel>
                                <Datetime
                                    value={this.state.end}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    onChange={this.handleEndChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleHide}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        onClick={this.handleExport}
                    >
                        Exporteren
                    </Button>
                    <span className="pull-left">
          </span>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    loginData: state.login
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            hide,
        },
        dispatch
    );

export default compose(
    connectModal({name: 'export'}),
    connect(mapStateToProps, mapDispatchToProps)
)(ExportModal);
